.breadcrumb {
  /* floating breadcrumb elements to add the search and location to it. */
  @include clearfix;
  margin-top: 20px;
  margin-left: 0px;
  background-color: $lightBackground;
  font-size: 12px;

  li {
    display: block;
    float: left;
  }

  li + li:before {
    color: #999;
  }

  a {
    font-weight: bold;
  }

  i{
    padding: 0 5px;
  }
}

.mobile-breadcrumb{
  margin: 15px 0;

  a{
    text-decoration: underline;
  }
}