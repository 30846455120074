.main-header .header-container, .main-footer .footer-container, .masonry-container, .content-container {
  max-width: 1200px;
  margin: 0 auto;
}

.main-header .title-wrapper, .content-container .inner-wrapper {
  margin: 0 20px;
}

.main-header .title-wrapper a img {
  margin: 0 0 11px;
  padding: 0;
  width: 381px !important;
}

.main-header .title-wrapper p {
  margin: 0 0 20px;
}

/*inside pages content editable from cms */
.page-content{
  ul, ol{
    margin-left: 40px;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .btn{
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
  }

  a{
    text-decoration: underline;
  }
}

.mf-system-design .page-body-wrapper {
  margin-bottom: 35px;
}
