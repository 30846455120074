$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// //Bootstrap import
// @import "bootstrap/bootstrap.scss";

// @import "legacy-bootstrap/variables.scss";
// @import "legacy-bootstrap/mixins.scss";
// @import "legacy-bootstrap/classes.scss";

// @import "font-awesome/font-awesome.scss";

//Application imports
@import "variables.scss";

@import "global.scss";

@import "breadcrumb.scss";

@import "form.scss";

@import "content.scss";
