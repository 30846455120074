body {
  color: #555;
}


a {
  color: $mfGlobalLinkDefaultColor;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #333333;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

h1, h2, h3{
  font-family: $mfGlobalBrandFont;
  color: $mfGlobalBrandColor;
}

h1, h2{
  font-weight: 400;
}

h3{
  font-size: 22px;
  font-weight: 300;
}

/*---xs devices, mobile phones ---*/
@media only screen and (max-width : 480px) {
  h1{
    font-size: 30px;
  }
  h3{
    font-size: 18px;
  }
}


hr{
  border-color: #ddd;
}

.btn {
  white-space: normal !important; /*to make long button responsive*/
  word-wrap: break-word;
}

.btn-outline{
  font-family: $mfGlobalBrandFont;
  border: 1px solid $mfGlobalBrandColor;
  border-radius: 0;
  color: $mfGlobalBrandColor;
  padding: 15px 20px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  background-color: transparent;
}

.btn-outline:hover {
  color: $white;
  background-color: $mfGlobalBrandColor;
}

.btn-primary{
  font-family: $mfGlobalBrandFont;
  border-radius: 0;
  border: none;
  color: $white;
  padding: 15px 20px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  background-color: $mfGlobalBrandColor;
}

.btn-primary:hover {
  background-color: #333333;
  border: none;
}

.row-vertical-space{
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.glyphicon-2x{
  font-size: 16px;
}

// Carousel Module
//====================================================================================================================================
//====================================================================================================================================


.banner-dark-bg{
  background-image: url(../assets/img/home/home_bg.png); background-repeat: repeat-x;
}

#headCarousel {
  max-width: 1500px;
  margin: auto;
}

.carousel-control {
  outline: none;
}

.carousel .item > img {
  margin: auto;
}

.carousel-control {
  top: 30%;
}


// Custom Carousel Control Module
//====================================================================================================================================
//====================================================================================================================================

.custom-carousel-control {

  //Need to use some magic numbers here as the layout does not accommodate 'special buttons' very well
  .carousel-control {
    width: auto;
    top: auto;
    font-size: 80px;
    left: auto;
    right: 0;

    &.top {
      top: -10px;
    }

    &.bottom {
      bottom: -10px;
    }

    &.left {
      right: 20px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}


/* Animation - W3.CSS 2.8 by Jan Egil and Borge Refsnes */
.w3-animate-fading{-webkit-animation:fading 10s infinite;animation:fading 10s infinite}
@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{-webkit-animation:opac 1.5s;animation:opac 1.5s}
@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}
@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;-webkit-animation:animatetop 0.4s;animation:animatetop 0.4s}
@-webkit-keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;-webkit-animation:animateleft 0.4s;animation:animateleft 0.4s}
@-webkit-keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;-webkit-animation:animateright 0.4s;animation:animateright 0.4s}
@-webkit-keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;-webkit-animation:animatebottom 0.4s;animation:animatebottom 0.4s}
@-webkit-keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0px;opacity:1}}
@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {-webkit-animation:animatezoom 0.6s;animation:animatezoom 0.6s}
@-webkit-keyframes animatezoom{from{-webkit-transform:scale(0)} to{-webkit-transform:scale(1)}}
@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
